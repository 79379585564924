import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SearchBar from 'components/search';

const Background = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.image})`};
`;

const Title = styled.div`
  width: 90vw;
`;

function FrontHeroMobile(props) {
  const { title, image } = props;
  return (
    <Box position="relative">
      <Background
        image={`${image}&lossless=1&ar=0.6:1&fit=crop&w=600&max-h=850&q=45`}
      >
        <Title>
          <Typography
            component="h1"
            variant="h1"
            color="secondary"
            align="center"
          >
            {title}
          </Typography>
        </Title>
      </Background>
      <SearchBar position="relative" frontPage />
    </Box>
  );
}

FrontHeroMobile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default FrontHeroMobile;
