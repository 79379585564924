import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { withStyles } from '@material-ui/styles';

const styles = {
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};
function VideoHero(props) {
  const { data, classes } = props;
  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100%;',
        pointerEvents: 'none',
      }}
    >
      <ReactPlayer
        className={classes.reactPlayer}
        url={data.embed_url}
        width="100%"
        height="100%"
        controls={false}
        muted={false}
        volume={0}
        playing={true}
        config={{
          youtube: {
            playerVars: { showinfo: 0, controls: 0, disablekb: 1 },
          },
        }}
      />
    </div>
  );
}

VideoHero.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoHero);
